import { ChevronDownIcon } from "@chakra-ui/icons";
import React, { useState } from "react";
import { Link } from "react-scroll";

export const DownChevron = ({
  nextSection,
  sx,
}: {
  nextSection: string;
  sx: {};
}) => {
  const [hover, setHover] = useState(false);

  const style = {
    ...sx,
    fontSize: "5xl",
    transition: "transform 1s",
    transform: hover ? "translateY(-10px)" : "",
    cursor: hover ? "pointer" : "default",
  };

  return (
    <Link
      activeClass="active"
      to={nextSection}
      spy={true}
      smooth={true}
      duration={500}
    >
      <ChevronDownIcon
        sx={style}
        onMouseEnter={() => {
          setHover(!hover);
        }}
        onMouseLeave={() => {
          setHover(!hover);
        }}
      />
    </Link>
  );
};

export default DownChevron;
