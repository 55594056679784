import { useLocation } from "react-router-dom";
import { ReactNode, useEffect, useState } from "react";
import Scroll from "react-scroll";

const RouterScroller = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const scroller = Scroll.animateScroll;
  const [sections, setSections] = useState<{ section: string; pos: number }[]>(
    []
  );

  const checkPathname = () => {
    if (location.pathname === "/") {
      if (!location.hash || location.hash.length < 1) scroller.scrollToTop();
      else if (location.hash && location.hash.length > 0) {
        const el = document.getElementById(location.hash.substring(1));
        if (el) scroller.scrollTo(el.offsetTop);
      }
    }
  };

  const mapSections = () => {
    const sections = document.getElementsByClassName("section");
    let sectionsAndPos = [];
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i] as HTMLElement;
      sectionsAndPos.push({ section: section.id, pos: section.offsetTop });
    }
    console.log(sectionsAndPos);
    setSections(sectionsAndPos);
  };

  useEffect(() => {
    mapSections();
    checkPathname();
  }, []);

  useEffect(() => {
    checkPathname();
  }, [location]);

  return <>{children}</>;
};

export default RouterScroller;
