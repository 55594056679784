import {
  Box,
  Center,
  Collapse,
  Flex,
  Icon,
  IconButton,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import { FaGithubSquare, FaLinkedin } from "react-icons/fa";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";

const SocialsBar = () => {
  const bg = useColorModeValue("blackAlpha.50", "whiteAlpha.200");
  const color = useColorModeValue("blackAlpha.700", "whiteAlpha.700");
  const linkedinColor = useColorModeValue("linkedin.300", "linkedin.500");
  const githubColor = useColorModeValue("purple.300", "purple.600");

  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const [showing, setShowing] = useState(false);

  useEffect(() => {
    setShowing(!isMobile);
  }, []);

  return (
    <Box
      rounded="lg"
      roundedBottom="none"
      borderColor={color}
      borderWidth={2}
      borderBottomWidth={0}
      bg={bg}
      color={color}
      position="fixed"
      bottom={0}
      right={10}
      p={2}
    >
      <Flex flexDir="column">
        <IconButton
          icon={showing ? <ChevronDownIcon /> : <ChevronUpIcon />}
          aria-label="Toggle socials bar"
          onClick={() => {
            setShowing(!showing);
          }}
          variant="unstyled"
          fontSize="2xl"
          height="auto"
          color={color}
        />
        <Collapse in={showing}>
          <Flex flexDir="column" gap={0.5}>
            <IconButton
              icon={<FaLinkedin />}
              aria-label="linkedin"
              variant="ghost"
              fontSize="2xl"
              _hover={{
                color: linkedinColor,
                bg: color,
              }}
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/in/olihulland/",
                  "_blank"
                );
              }}
            />
            <IconButton
              icon={<FaGithubSquare />}
              aria-label="github"
              variant="ghost"
              fontSize="2xl"
              _hover={{
                color: githubColor,
                bg: color,
              }}
              onClick={() => {
                window.open("https://github.com/olihulland", "_blank");
              }}
            />
          </Flex>
        </Collapse>
      </Flex>
    </Box>
  );
};

export default SocialsBar;
