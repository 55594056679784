import { Center, Heading, VStack } from "@chakra-ui/react"
import { ViewportSection } from "../components/Section"
import { Intro } from "../sections/home/Intro"

export const Home = () => {
  return (
    <VStack className="home">
      <Intro />
      <ViewportSection sectionName="building">
        <Center h="100%">
          <Heading>This site is still being built ...</Heading>
        </Center>
      </ViewportSection>
    </VStack>
  )
}