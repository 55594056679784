import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

type Props = {
  sectionName: string;
  children?: ReactNode;
  height?: string;
  bg?: string;
  minHeight?: string;
  subTopBar?: boolean;
};

export const Section: React.FC<Props> = ({
  sectionName,
  children,
  height,
  bg,
  minHeight,
  subTopBar,
}) => {
  let h = height;
  let mh = minHeight;
  if (subTopBar) {
    const topBar = document.getElementById("top-bar");
    const topBarHeight = topBar?.clientHeight;
    if (topBarHeight && h) h = `calc(${h} - ${topBarHeight}px)`;
    if (topBarHeight && mh) mh = `calc(${mh} - ${topBarHeight}px)`;
  }
  return (
    <Box
      className="section"
      id={sectionName}
      h={h}
      w="full"
      bgColor={bg}
      minH={mh}
    >
      {children}
    </Box>
  );
};

export const ViewportSection: React.FC<Props> = ({
  sectionName,
  children,
  bg,
  subTopBar,
}) => {
  return (
    <Section
      sectionName={sectionName}
      height="100vh"
      bg={bg}
      subTopBar={subTopBar}
    >
      {children}
    </Section>
  );
};

export const ViewportPlusSection: React.FC<Props> = ({
  sectionName,
  children,
  bg,
  subTopBar,
}) => {
  return (
    <Section
      sectionName={sectionName}
      minHeight="100vh"
      bg={bg}
      subTopBar={subTopBar}
    >
      {children}
    </Section>
  );
};
