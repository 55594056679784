import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  fonts: {
    heading: `"Fira Code", 'monospace'`,
  },
  textStyles: {
    heavy: {
      fontWeight: '700',
    },
    normal: {
      fontWeight: '400',
    }
  },
});