import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  chakra,
  Flex,
  Heading,
  Spacer,
  Stack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useState } from "react";
import DownChevron from "../../components/DownChevron";
import { ViewportSection } from "../../components/Section";

export const Intro = () => {
  const [smileyHover, setSmileyHover] = useState<boolean>(false);
  const smiley = smileyHover ? "😊" : "🙂";
  const smileyStyle = {
    display: "inline-block",
    transition: "transform 0.5s",
    transform: smileyHover ? "scale(1.2) rotate(6deg)" : "",
    cursor: smileyHover ? "grabbing" : "",
  };

  const [linkHover, setLinkHover] = useState<boolean>(false);
  const linkWeight = linkHover ? 500 : 400;

  return (
    <ViewportSection sectionName="intro" subTopBar={true}>
      <Box w="full" h="full" py={5}>
        <Flex
          flexDir="column"
          maxW="80%"
          minH="50%"
          position="relative"
          top="50%"
          transform="translateY(-50%)"
        >
          <Wrap paddingBottom={30}>
            <WrapItem>
              <Center h="full">
                <Heading fontWeight="500" fontSize="4xl">
                  Welcome!
                </Heading>
              </Center>
            </WrapItem>
            <WrapItem>
              <Center h="full">
                <Box
                  onMouseEnter={() => {
                    setSmileyHover(!smileyHover);
                  }}
                  onMouseLeave={() => {
                    setSmileyHover(!smileyHover);
                  }}
                  sx={smileyStyle}
                  fontSize="4xl"
                >
                  {smiley}
                </Box>
              </Center>
            </WrapItem>
          </Wrap>

          <Heading fontWeight="400">
            I'm <chakra.span fontWeight="700">Oli</chakra.span>, a third year
            Computer Science student at&nbsp;
            <a
              href="https://www.lancaster.ac.uk/study/undergraduate/courses/computer-science-with-industrial-experience-msci-hons-g404/"
              style={{
                color: "red",
                textDecoration: "none",
                fontWeight: linkWeight,
              }}
              onMouseEnter={() => {
                setLinkHover(!linkHover);
              }}
              onMouseLeave={() => {
                setLinkHover(!linkHover);
              }}
            >
              Lancaster University
              <ExternalLinkIcon
                fontSize={15}
                sx={{ verticalAlign: "bottom" }}
              />
            </a>
            .
          </Heading>
          <Spacer />
          <DownChevron nextSection="building" sx={{ marginTop: "20px" }} />
        </Flex>
      </Box>
    </ViewportSection>
  );
};
