import * as React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  ChakraProvider,
  Box,
  Spacer,
  Flex,
  useMediaQuery,
  Container,
  Center,
} from "@chakra-ui/react";
import { Home } from "./pages/Home";
import Menu from "./components/Menu";

import { theme } from "./theme";
import "@fontsource/fira-code/400.css";
import "@fontsource/fira-code/500.css";
import "@fontsource/fira-code/700.css";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import RouterScroller from "./components/RouterScroller";
import SocialsBar from "./components/SocialsBar";

export const App = () => {
  return (
    <Router>
      <RouterScroller>
        <ChakraProvider theme={theme}>
          <Box className="app" w="full" h="full" p={5} m={0}>
            <Container maxW="container.xl" id="top-bar">
              <Flex>
                {/*<Menu />*/}
                <Spacer />
                <ColorModeSwitcher />
              </Flex>
            </Container>
            <Container maxW="container.lg">
              <Routes>
                <Route index element={<Home />} />
              </Routes>
            </Container>
          </Box>
          <SocialsBar />
        </ChakraProvider>
      </RouterScroller>
    </Router>
  );
};
